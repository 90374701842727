import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`28th April 2021`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">{`You can now monitor the state of all live threads and heap using `}<a parentName="li" {...{
          "href": "type://ManagementService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/management/ManagementService.html"
        }}>{`type://ManagementService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3463"
        }}>{`#3463`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Server
Server.builder()
      .serviceUnder("/internal/management/", ManagementService.of())
      ...
// Client
WebClient client = ...
client.get("/internal/management/jvm/threaddump").aggregate().join();
client.get("/internal/management/jvm/heapdump").aggregate().join();
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now set the abbreviated service name that is similar to the abbreviated logger name of
`}<a parentName="li" {...{
          "href": "http://logback.qos.ch/manual/layouts.html#ClassicPatternLayout"
        }}>{`Logback`}</a>{` via
`}<a parentName="li" {...{
          "href": "type://ServiceNaming#shorten(int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceNaming.html#shorten(int)"
        }}>{`type://ServiceNaming#shorten(int)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3453"
        }}>{`#3453`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .defaultServiceNaming(ServiceNaming.shorten(30))
      ...
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`You no longer see a wrong `}<inlineCode parentName="li">{`serviceName`}</inlineCode>{` for annotated services when `}<a parentName="li" {...{
          "href": "type://ServiceNaming:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceNaming.html"
        }}>{`type://ServiceNaming`}</a>{` is used. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3468"
        }}>{`#3468`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`ServiceRequestContext.config().defaultServiceName()`}</inlineCode>{` no longer returns `}<inlineCode parentName="li">{`null`}</inlineCode>{` if a default service name
is specified.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You no longer see a `}<a parentName="li" {...{
          "href": "type://ClosedStreamException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/ClosedStreamException.html"
        }}>{`type://ClosedStreamException`}</a>{` when a gRPC call is cancelled by a client. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3452"
        }}>{`#3452`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3455"
        }}>{`#3455`}</a></li>
      <li parentName="ul">{`You no longer see the `}<inlineCode parentName="li">{`Failed to force-initialize 'io.netty.channel.unix.Socket'`}</inlineCode>{` error message when
Armeria attempts to load native libraries. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3457"
        }}>{`#3457`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3485"
        }}>{`#3485`}</a></li>
      <li parentName="ul">{`You can now specify an absolute URI using `}<a parentName="li" {...{
          "href": "type://WebClient#prepare():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html#prepare()"
        }}>{`type://WebClient#prepare()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3479"
        }}>{`#3479`}</a>
        <ul parentName="li">
          <li parentName="ul">{`You can specify a query string both in a path string and via
`}<a parentName="li" {...{
              "href": "type://WebClientRequestPreparation#queryParam(String,Object):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClientRequestPreparation.html#queryParam(java.lang.String,java.lang.Object)"
            }}>{`type://WebClientRequestPreparation#queryParam(String,Object)`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You no longer see redundant parameters in `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` when Kotlin data class is used. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3454"
        }}>{`#3454`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3461"
        }}>{`#3461`}</a></li>
      <li parentName="ul">{`You no longer see an `}<inlineCode parentName="li">{`IllegalStateException`}</inlineCode>{` when a `}<a parentName="li" {...{
          "href": "type://StreamMessage#of(Path):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#of(java.nio.file.Path)?full"
        }}>{`type://StreamMessage#of(Path)?full`}</a>{` or
`}<a parentName="li" {...{
          "href": "type://StreamMessage#of(File):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#of(java.io.File)?full"
        }}>{`type://StreamMessage#of(File)?full`}</a>{` is used in a client-side. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3450"
        }}>{`#3450`}</a></li>
      <li parentName="ul">{`You no longer have to specify a custom `}<a parentName="li" {...{
          "href": "type://JacksonRequestConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/JacksonRequestConverterFunction.html"
        }}>{`type://JacksonRequestConverterFunction`}</a>{` or
`}<a parentName="li" {...{
          "href": "type://JacksonResponseConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/JacksonResponseConverterFunction.html"
        }}>{`type://JacksonResponseConverterFunction`}</a>{` for Kotlin data class and Scala case class if you add
`}<inlineCode parentName="li">{`com.fasterxml.jackson.module:jackson-module-kotlin`}</inlineCode>{` or `}<inlineCode parentName="li">{`com.fasterxml.jackson.module:jackson-module-scala`}</inlineCode>{`
as a dependency. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3458"
        }}>{`#3458`}</a></li>
      <li parentName="ul">{`You no longer see an `}<inlineCode parentName="li">{`EofException`}</inlineCode>{` when writing to a timed-out response stream in `}<a parentName="li" {...{
          "href": "type://JettyService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyService.html"
        }}>{`type://JettyService`}</a>{`.
It's discarded silently. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3460"
        }}>{`#3460`}</a></li>
      <li parentName="ul">{`You no longer see a `}<a parentName="li" {...{
          "href": "type://CancellationException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/CancellationException.html"
        }}>{`type://CancellationException`}</a>{` when an exception is raised by
a `}<a parentName="li" {...{
          "href": "type://FilteredStreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/FilteredStreamMessage.html"
        }}>{`type://FilteredStreamMessage`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3449"
        }}>{`#3449`}</a></li>
      <li parentName="ul">{`You no longer see the `}<a parentName="li" {...{
          "href": "type://RequestTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/RequestTimeoutException.html"
        }}>{`type://RequestTimeoutException`}</a>{` log message when a response stream fails due to
cancellation or timeout. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3459"
        }}>{`#3459`}</a></li>
      <li parentName="ul">{`A timeout exception is created only when a request or response is timed out. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3462"
        }}>{`#3462`}</a></li>
      <li parentName="ul">{`Armeria now rejects all CONNECT requests explicitly, except HTTP/2 CONNECT requests with a `}<inlineCode parentName="li">{`:protocol`}</inlineCode>{`
pseudo header. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3482"
        }}>{`#3482`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Caffeine 2.8.8 → 2.9.0`}</li>
      <li parentName="ul">{`Dropwizard 2.0.20 → 2.0.21`}</li>
      <li parentName="ul">{`Dropwizard metrics 4.1.18 → 4.1.20`}</li>
      <li parentName="ul">{`gRPC 1.36.1 → 1.37.0`}</li>
      <li parentName="ul">{`Micrometer 1.6.5 → 1.6.6`}</li>
      <li parentName="ul">{`Jackson 2.12.2 → 2.12.3`}</li>
      <li parentName="ul">{`java-jwt 3.14.0 → 3.15.0`}</li>
      <li parentName="ul">{`Reactor 3.4.4 → 3.4.5`}</li>
      <li parentName="ul">{`RxJava 3.0.11 → 3.0.12`}</li>
      <li parentName="ul">{`scalapb-runtime_2.12 0.11.1 → 0.11.2`}</li>
      <li parentName="ul">{`Spring Boot 2.4.4 → 2.4.5`}</li>
      <li parentName="ul">{`Spring web 5.3.5 → 5.3.6`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'fclemonschool', 'heowc', 'hexoul', 'ikhoon', 'minwoox', 'trustin', 'Waynefn']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      